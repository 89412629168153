<template>
  <!-- card-hover-shadow  -->
  <base-card class="card-hover" :class="cardHoverShadow ? 'card-hover-shadow' : ''">
    <!-- <v-chip class="ma-2 p-absolute" color="error" small> 56% off </v-chip> -->
    <div class="card-hover-icon">
      <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 card-hover-icon">
        <!-- dialog -- modal  -->
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addProductToCart()" icon :title="'Adicionar ' + produto.designacao + ' ao carrinho'" color="secondary" v-bind="attrs" v-on="on">
              <v-icon>mdi-cart-plus</v-icon>
            </v-btn>

            <v-btn icon :title="'Ver detalhes da(o) ' + produto.designacao" color="secondary" v-bind="attrs" v-on="on">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <base-card>
            <v-card-title>
              <v-btn class="mt-4" absolute right icon color="secondary" text @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <!-- <v-img height="250" :src="produto.imagem_produto"></v-img> -->
                  <v-img width="300" :src="SERVER + this.produto.imagem_produto" :alt="produto.designacao" />

                  <!-- <img v-if="imageExists" height="250" :src="SERVER+this.produto.imagem_produto" alt="Imagem do Produto" />
                  <img v-else height="250" src="@/assets/images/no_image.png" alt="Imagem padrão" /> -->
                  <!-- @/assets/images/no_image.png -->
                </v-col>
                <v-col cols="12" md="6" class="mt-4 mb-4 leading-tight d-inline-block text-truncate">
                  <span class="text-h6 font-weight-medium">
                    {{ produto ? produto.designacao : "" }}
                  </span>

                  <div class="d-flex mb-3">
                    <h5 class="grey--text text--darken-5 mr-2">
                      Classificação:
                    </h5>
                    <div class="d-flex mb-1">
                      <!--                           parseInt(
                            produto.classificacao[0].classificacao == null
                              ? 5
                              : produto.classificacao[0].classificacao
                          ) -->
                      <v-rating :value="produto.classificacao[0]?produto.classificacao[0].classificacao:5" color="amber" dense half-increments readonly size="16"></v-rating>

                      <div class="grey--text text--darken-1 font-weight-bold ml-1">
                        ({{produto.classificacao[0]?produto.classificacao[0].classificacao:5 }})
                      </div>
                      <!-- {{
                          produto.classificacao[0].classificacao == null
                            ? 5
                            : produto.classificacao[0].classificacao
                        }} -->
                    </div>
                  </div>

                  <h2 class="primary--text mb-2">
                    {{ formatePrice(produto.pvp) }} AKZ
                  </h2>
                  <v-btn @click="addProdutoCarrinho()" v-if="checkProdutoTemStock(produto)" color="secondary" class="text-capitalize mb-3" :class="'d-block'">
                    <v-icon>mdi-cart-plus</v-icon> Adicionar ao carrinho
                  </v-btn>

                  <p class="red--text text-decoration-line-through font-weight-bold text-uppercase" v-if="!checkProdutoTemStock(produto)">
                    <!-- stock esgotado -->
                    <!-- -{{ checkProdutoTemStock(produto)  }} -->
                  </p>

                  <!-- <v-row>
                    <v-col cols="12">
                      <h6>
                        {{ produto.existencia_estock }}
                      </h6>
                    </v-col>
                  </v-row> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-right noprint">
                  <span>Partilhar Link:</span>
                  <share-it :text="
                      produto.designacao +
                      ' na Mutue Loja Online por apenas ' +
                      formatePrice(produto.pvp) +
                      ' AKZ'
                    " :url="
                      'https://loja.mutue.net/#/portal/pages/produto/detalhes/' +
                      produto.uuid
                    " icons outline :targets="['whatsapp', 'facebook']" />
                </v-col>
              </v-row>
            </v-card-text>
          </base-card>
        </v-dialog>
      </div>
    </div>

    <router-link class="custom-link" :to="{
        name: 'ProdutoDetalhes',
        params: { produto_id: produto.uuid },
      }">
      <!-- <v-img height="250" class="br-t-8" :src="contentImg"></v-img> -->
      <!-- <img v-if="imageExists" height="250" class="br-t-8" :src="contentImg" />
      <img v-else height="250" class="br-t-8" src="@/assets/images/no_image.png" alt="Imagem padrão" /> -->
      <v-img max-width="300" :title="'Ver detalhes de: ' + produto.designacao" height="300" :src="SERVER + contentImg" />

      <v-card-text :title="'Ver detalhes de: ' + produto.designacao" name="card-produto-layout" class="d-flex justify-content-between align-end">
        <div class="flex-grow-2 my-3">
          <h6 class="text-uppercase text--darken-4">
            {{ limitText(contentText) }}
          </h6>
          <h6 class="text-uppercase text--darken-4">
            {{ remainingText }}
          </h6>
          <h6 v-if="remainingText=='' && limitText(contentText).length>=5" class="text-uppercase white--text">
            MUTUE LOJA ONLINE
          </h6>

          <div class="d-flex mb-1">
            <!--   produto.classificacao[0].classificacao == null ||
                !produto.classificacao[0].classificacao
                  ? 4
                  : parseInt(produto.classificacao[0].classificacao)
               -->
            <v-rating :value="5" color="amber" background-color="amber" dense half-increments readonly size="17"></v-rating>
            <!-- <div class="grey--text ml-1">
            {{ produto.classificacao[0].classificacao }}
          </div> -->
          </div>
          <div class="d-flex">
            <h6 class="primary--text mr-2 mb-0">
              <!-- {{ produto.classificacao[0]?produto.classificacao[0].classificacao:5 }} -->
              {{ formatePrice(produto.pvp) }} AKZ
            </h6>
            (IVA incluido)
            <!-- <h6 class="gray--text lighten-4 text-decoration-line-through mb-0">
            {{ formatePrice(applyDiscount(amount)) }}
          </h6> -->
          </div>
        </div>
      </v-card-text>
    </router-link>

    <v-dialog name="dialog-produto-adicionado-favoritos" v-model="dialogAddedFavoritos" max-width="370">
      <v-card>
        <v-divider />
        <v-card-text>
          <div class="text-center">
            <v-icon color="secondary" size="60px">mdi-heart</v-icon>
          </div>
          <v-row class="text-left mt-2 text-center text-h6 secondary--text">
            <span>
              <span class="font-weight-bold">{{ produto.designacao }} </span>
              adicionado aos favoritos com sucesso!
            </span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-produto-adicionado-carrinho" v-model="dialogProdutoAddedToCart" max-width="350" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="70">mdi-check-circle</v-icon>
          <div class="mt-2">
            <span class="text-subtitle1 font-weight-bold">
              {{ message }}
            </span>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn dense outlined @click="closeDialogs()" text>
            <!-- <v-icon>mdi-</v-icon>  -->
            Comprar Mais
          </v-btn>
          <!-- <br /> -->
          <v-btn color="green" class="white--text ml-2" :to="{ name: 'CarrinhoResumo' }" dense>
            Finalizar Compra
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-logout-sucesso" v-model="dialogNoStock" max-width="300">
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="error" class="mt-2" size="50">mdi-close</v-icon>
          <h6 class="mt-2">{{ message }}</h6>
        </v-card-text>
      </v-card>
    </v-dialog>
  </base-card>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";

import {
    LOGIN_URL,
    SERVIDOR,
    BASE_URL
} from "@/configs/api";
import axios from "axios";
import {
    barramento
} from "../../barramento";

export default {
    props: {
        btnText: {
            type: String,
            default: "",
        },
        cardHoverShadow: {
            type: Boolean,
            default: false,
        },
        counter: {
            type: Number,
            default: 0,
        },
        contentImg: String,
        contentText: String,
        amount: {
            type: Number,
            default: 200,
        },
        contentRating: {
            type: Number,
            default: 4.5,
        },
        // classificacao: {
        //   type: Number,
        //   default: 4.5,
        // },
        produto: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters(["getProducts"]),
    },
    data() {
        return {
          mainText:"",  
          remainingText: "",
            dialogNoStock: false,
            SERVER: SERVIDOR,
            dialogProdutoAddedToCart: false,
            message: null,
            dialogAddedFavoritos: false,
            dialog: false,
            imageExists: true,
        };
    },

    mounted() {
      },
    methods: {
        checkProdutoTemStock(produto) {
            if (produto.existencia_estock) {
                return produto.existencia_estock[0].quantidade > 0;
            }
            else 
            return false
        },
        checkImageExists() {
            const img = new Image();
            img.src = this.produto.imagem_produto;

            img.onload = () => {
                // A imagem existe no servidor
                this.imageExists = true;
            };
            img.onerror = () => {
                // A imagem não existe no servidor
                this.imageExists = false;
            };
        },
        closeDialogs() {
            this.dialogProdutoAddedToCart = false;
            this.dialog = false;
        },
        addProductToCart() {
            // alert("ADD NO CART");
            let produto = this.produto;
            const url = `${BASE_URL}/carrinho/add/produto`;
            let token = localStorage.getItem("TOKEN");

            if (!token) {
                setTimeout(() => {
                    this.loading = true;
                }, 4000);
                this.$router.push({
                    name: "PortalLogin",
                });
            } else {
                axios
                    .post(
                        url, {
                            uuid: produto.uuid,
                        }, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    )
                    .then(() => {
                        this.message =
                            this.produto.designacao + " adicionado ao carrinho com sucesso!";
                        this.dialogProdutoAddedToCart = true;
                        barramento.$emit("adicionarCarrinho");

                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    })
                    .catch((error) => {
                        if (error.response.data.error) {
                            setTimeout(() => {
                                this.loading = false;
                            }, 300);
                            this.message = error.response.data.error;
                            this.dialogNoStock = true;
                        }
                    });
            }
        },
        addProdutoCarrinho() {
            let produto = this.produto;
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");

            if (!TOKEN_SESSAO) {
                setTimeout(() => {
                    this.loading = true;
                }, 4000);
                this.$router.push({
                    name: "PortalLogin",
                });
            } else {
                const url = `${BASE_URL}/carrinho/add/produto`;
                let token = localStorage.getItem("TOKEN");
                axios
                    .post(
                        url, {
                            uuid: produto.uuid,
                        }, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    )
                    .then(() => {
                        this.message =
                            this.produto.designacao + " adicionado ao carrinho com sucesso!";
                        this.dialogProdutoAddedToCart = true;
                        barramento.$emit("adicionarCarrinho");

                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    })
                    .catch((error) => {
                        if (error.response.data.error) {
                            setTimeout(() => {
                                this.loading = false;
                            }, 300);
                            this.message = error.response.data.error;
                            this.dialogNoStock = true;
                        }
                    });
            }
        },

        getTotalProdutosCarrinho() {
            this.loading = true;
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");
            if (TOKEN_SESSAO) {
                const url = `${BASE_URL}/carrinho/get/my/produtos`;
                axios
                    .get(url, {
                        headers: {
                            Authorization: "Bearer " + TOKEN_SESSAO,
                        },
                    })
                    .then((response) => {
                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    })
                    .catch((error) => {
                        console.log(`error ${error}`);
                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    });
            }
        },
        limitText(texto, limite = 28) {
            if (texto.length > limite) {
                // return texto.substring(0, limite) + "...";
                const truncatedText = texto.substring(0, limite);
                this.remainingText = texto.substring(limite);
                // return `${truncatedText}\n${remainingText}`;
                return truncatedText
            }
            return texto;
        },

        addProdutoNosFavoritos() {
            this.loading = true;
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");
            if (!TOKEN_SESSAO) {
                setTimeout(() => {
                    this.loading = true;
                }, 4000);
                this.$router.push({
                    name: "PortalLogin",
                });
            } else {
                const url = `${LOGIN_URL}/empresa/checkFavorito`;
                axios
                    .post(
                        url, {
                            produto_id: this.produto.id,
                        }, {
                            headers: {
                                Authorization: "Bearer " + TOKEN_SESSAO,
                            },
                        }
                    )
                    .then((response) => {
                        this.dialogAddedFavoritos = true;
                        this.message = response.data.message;
                        // alert(JSON.stringify(response.data));
                        setTimeout(() => {
                            this.loading = false;
                        }, 1500);
                    })
                    .catch((error) => {
                        console.log(`error ${error}`);
                    });
            }
        },

        showDialogAddedFavoritos() {
            this.addProdutoNosFavoritos();
        },
        ...mapActions(["addCart"]),
        applyDiscount(preco) {
            return parseInt(preco + preco * 0.1);
        },
        formatePrice(value) {
        let val = Number(value).toFixed(2).replace(".", ",");
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },
};
</script>

<style lang="scss" scoped>
.br-t-8 {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.card-hover {
  &:hover {
    .card-hover-icon {
      display: block;
    }
  }

  .card-hover-icon {
    display: none;
  }
}
</style><style>
.custom-link {
  text-decoration: none;
  /* Remove default text decoration */
  color: #000;
  /* Set the link color */
}

.custom-link:visited {
  /* Style for visited link (after it has been clicked) */
  text-decoration: none;
  /* Remove text decoration */
  color: #555;
  /* Set the color for visited links */
}
</style>
